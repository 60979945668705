export default {
  title: 'Data Observatory',
  description:
    'The Data Observatory is a spatial data catalog that provides access to thousands of datasets from hundreds of sources.',
  subtitle: 'Spatial Data Catalog',
  loading: 'Loading...',
  showFilters: 'Show filters',
  hideFilters: 'Hide filters',
  allProviders: 'All Providers',
  otherProviders: '+50 market leaders',
  resultsCount: {
    pagination: '{offset}-{limit}',
    plural: '{pagination} of {count} results',
    singular: '1 result'
  },
  search: {
    startTyping: 'Start typing to search...',
    placeholder: 'Search data product or provider',
    searchFor: 'Search for "{text}"',
    aiSearchFor: 'Ask for "{text}"',
    cancel: 'Cancel',
    enterPress: 'Enter ↵',
    dataProductsCount: '{datasetCount} data products',
    datasetList: {
      title: 'Popular data products'
    },
    providerList: {
      title: 'Top providers'
    },
    categoryList: {
      title: 'Featured categories'
    },
    ai: {
      loading: 'Generating your answer...'
    }
  },
  filter: {
    globalDatasetsAlert: 'You can find {count} more data products in the Global option.',
    order: {
      rank: 'Featured',
      mostRecent: 'Most recent',
      az: 'A-Z'
    },
    subscription: {
      all: 'All',
      subscribed: 'Subscribed',
      requested: 'Requested'
    },
    widget: {
      defaultTitle: 'Filters',
      clearAll: 'Clear',
      hideFilters: 'Hide filters',
      singleFilterSelected: '1 filter',
      multipleFiltersSelected: '{nbr} filters',
      noResultsSuggestion: "Try adjusting your search to find what you're looking for.",
      countries: {
        title: 'Countries',
        placeholder: 'Find a country',
        noResults: 'No countries for your search',
        nbSelectedElements: '{nbr} countries'
      },
      categories: {
        title: 'Categories',
        placeholder: 'Find a category',
        noResults: 'No categories for your search',
        nbSelectedElements: '{nbr} categories'
      },
      sources: {
        title: 'Sources',
        placeholder: 'Find a source',
        noResults: 'No source for your search',
        nbSelectedElements: '{nbr} sources'
      },
      providers: {
        title: 'Providers',
        placeholder: 'Find a provider',
        noResults: 'No providers for your search',
        nbSelectedElements: '{nbr} providers'
      },
      useCases: {
        title: 'Use cases',
        placeholder: 'Find a use case',
        noResults: 'No use cases for your search',
        nbSelectedElements: '{nbr} use cases'
      },
      temporalAggs: {
        title: 'Temporal aggregations',
        placeholder: 'Find a temporal aggregation',
        noResults: 'No temporal aggregations for your search',
        nbSelectedElements: '{nbr} temp aggr.'
      },
      spatialAggs: {
        title: 'Spatial aggregations',
        placeholder: 'Find a spatial aggregation',
        noResults: 'No spatial aggregations for your search',
        nbSelectedElements: '{nbr} spatial aggr.'
      },
      licenses: {
        title: 'Licenses',
        placeholder: 'Find a license',
        noResults: 'No licenses for your search',
        nbSelectedElements: '{nbr} licenses'
      }
    }
  },
  commons: {
    browseHome: 'Return to home'
  },
  publicFooter: {
    unlock: 'Unlock the power of spatial analysis.',
    terms: 'Terms',
    privacy: 'Privacy Notice',
    copy: '© CARTO {year}'
  },
  home: {
    categories: 'Categories',
    providers: 'Providers',
    recentsDatasets: 'Recently added',
    popularDatasets: 'Most popular',
    browseLink: 'Browse the catalog',
    footer: {
      body: 'This project has received funding from the {link} research and innovation programme.',
      link: "European Union's Horizon 2020",
      agreement: 'Grant Agreement',
      numberAgreement: 'No 960401'
    }
  },
  card: {
    from: 'From',
    sample: 'Free sample',
    category: {
      caption: '{elements} data products'
    }
  },
  browser: {
    searchResults: 'Data products for "{fullTextSearch}"',
    dataProducts: 'Data products',
    clearFilters: 'Clear filters',
    clear: 'Clear',
    noResults: {
      title: 'No data products were found',
      subtitle: "Try adjusting your search to find the products you're looking for or clear your filters.",
      stillSearch: "Still can't find what you’re looking for?",
      requestInfo: 'Get help from our team'
    }
  },
  productFile: {
    documentationLink: 'More info',
    frequentlyQuestions: 'Frequently Asked Questions',
    header: {
      requestSubscription: 'Request subscription',
      publicRequestSubscription: "I'm interested",
      tryForFree: 'Try for free'
    },
    tabs: {
      details: 'Summary',
      variables: 'Data',
      faqs: 'FAQs',
      mapPreview: 'Map'
    },
    variables: {
      noDataMap: 'Map preview not available.',
      noDataSample: 'Data preview not available.',
      thereSampleFor: ' This one is for {dataset}',
      dataSample: 'Data sample',
      limitedResults: 'Preview limited to 10 rows',
      dataDictionary: 'Data dictionary',
      countInfoRows: '{value} rows',
      countInfoColumns: '{value} columns',
      columnName: 'Column name',
      type: 'Type',
      total: 'Total',
      description: 'Description',
      slug: 'Slug'
    },
    details: {
      showAll: 'Show more',
      showLess: 'Show less',
      productDescription: 'Product description',
      providerWebsite: 'Provider website',
      viewProduct: 'View product',
      useCases: 'Use-cases',
      licenseLink: 'Terms and conditions',
      boughtTogetherProducts: 'Often purchased together',
      moreProviderProducts: 'More products from {providerName}',
      moreProviderProductsNoData: 'More products',
      viewAllProviderDatasets: 'All products from this provider',
      title: 'Details',
      viewAllVariables: 'See all variables',
      category: 'Category',
      license: 'License',
      keyVariables: 'Key variables',
      provider: 'Provider',
      country: 'Country',
      placeType: 'Place type',
      spatialAggregation: 'Spatial aggregations',
      temporalAggregation: 'Temporal aggregation',
      updateFrequency: 'Update frequency',
      geographyName: 'Associated geography'
    }
  },
  actions: {
    goToExplorer: 'Go to Data Explorer',
    subscribeFree: 'Subscribe for free',
    subscribeSample: 'Access free sample',
    subscribePremium: 'Request subscription',
    subscriptionRequested: 'Cancel request',
    alreadySampleSubscribed: 'View sample',
    alreadyPublicSubscribed: 'View data',
    accessData: 'Access data',
    dismiss: 'Dismiss'
  },
  subscription: {
    status: {
      subscribed: 'Subscribed',
      requested: 'Requested',
      cancel: 'Cancel request',
      expiresSoon: 'Expires soon',
      tooltip: {
        requested: {
          admin:
            'A CARTO team member will get in touch to give you more information and go over any questions you may have.',
          editor: 'For more information, please contact your CARTO admin.'
        },
        subscribed: {
          noListSubscriptionEnabled: 'For more information, please contact a CARTO team member.',
          admin: 'View all my subscription',
          editor: 'For more information, please contact your CARTO admin.'
        },
        expiresSoon: {
          noListSubscriptionEnabled: 'For more information, please contact a CARTO team member.',
          admin: 'View all my subscription',
          editor: 'For more information, please contact your CARTO admin.'
        }
      }
    },
    expiresAt: 'Expires {expires}',
    expiresSoon: '{expires} days left',
    title: 'Confirm your subscription',
    subtitle: "You're going to subscribe to the following dataset",
    terms: 'I accept the License',
    connect: 'Confirm subscription',
    request: {
      modalTitle: 'Request subscription',
      requestSubscription: 'Request subscription',
      requestFreeAccess: 'Subscribe for free',
      title: 'Confirm your request',
      subtitle: "You're going to request a subscription for the following dataset",
      info: 'Once you confirm your request, a CARTO team member will get in touch to give you more information and go over any questions you may have.',
      confirmRequest: 'Confirm request',
      confirmSubscription: 'Confirm subscription',
      acceptLicense: 'I accept the License',
      licenseInfo: 'This data is subject to {licenseLink}'
    },
    cancel: {
      modalTitle: 'Cancel request',
      content: 'Are you sure you want to cancel the subscription request for data {datasetName}?',
      reminder: 'You are not subscribed yet. You can request it again later.',
      keep: 'Keep the request',
      confirm: 'Yes, cancel'
    },
    alert: {
      public: 'Public',
      premium: 'Premium',
      sample: 'You have a sample of this dataset available in the Data Explorer.',
      active: 'Active subscription',
      subscribed: 'You have an {active} to this {type} dataset.',
      requested:
        'You have requested a subscription to this dataset. A CARTO team member will get in touch to give you more information.',
      dismiss: 'Changed your mind? <a>Dismiss it.</a>'
    },
    dismiss: {
      title: 'Dismiss request',
      message: 'Are you sure you want to cancel the subscription request? You can request it again at any time.',
      confirm: 'Yes, dismiss',
      cancel: 'No, keep it'
    }
  },
  accessSample: {
    modalTitle: 'Access free sample',
    title: 'Connect your sample',
    termsUseTitle: 'Terms of use',
    subtitle: "You're going to access the following sample dataset:",
    disclaimer:
      'The sample data is for trial evaluation purposes only and may differ slightly from product data. It may be used internally only for non-commercial purposes. This sample is subject to limited, non-exclusive, non-transferable, non-sublicensable and revocable rights and license to use. Any rights not expressly granted are withheld.',
    terms: 'I accept the Terms of Use',
    connect: 'Connect sample',
    accessSample: 'Access sample',
    noSampleRequest: 'No samples available for {name}. ',
    anotherSourceRequest: 'Instead, we recommend this similar free sample: {dataset}',
    sampleNotAvailable: 'Sample not available: this sample is for {dataset}',
    somethingWentWrong: 'Something went wrong'
  }
}
